import React from 'react';

import BasicTemplate from '../../templates/BasicTemplate/BasicTemplate';

const AboutPage = () => (
  <BasicTemplate>
    <h1> Oops... </h1>
  </BasicTemplate>
);

export default AboutPage;
